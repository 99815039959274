import { Calendar, Bookmark, Award, Messages1, Bank, MoneyRecive, Tree, ShoppingBag, Rank, Document, User, People, Headphone  } from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";
import { RiHistoryLine, RiListIndefinite, RiRobotLine, RiTrademarkLine } from 'react-icons/ri';
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import BotIcon from "../../assets/icons/bot.svg"
import Account from "../../assets/icons/account.svg"
import Support from "../../assets/icons/support.svg"
import Chart from "../../assets/icons/chart.svg"
import Users from "../../assets/icons/users.svg"
import Wallet from "../../assets/icons/wallet.svg"
import { Children } from 'react';


const apps = [
    {
        header: "",
    },
//    {
//        id: "user-interface-icons",
  //      title: "Robot",
    //    icon: <Award size={18} />,
      //  children: [
        //    {
          //      id: "user-interface-remix-icons",
            //    title: "Comprar plan",
              //  navLink: "/buy-plans",
            //},
           // {
           //     id: "user-interface-remix-icons",
             //   title: "Configuracion",
               // navLink: "/app/dashboard",
           // },       
       // ]
    //},\

   // {
   //   id: "operations",
   //   title: "Operar",
   //
   //   icon: <img src={Chart} height="30" alt="Bot Icon" />, // Aquí se utiliza el icono SVG importado como una imagen
   //   navLink: "/market",
   // },
  {
        id: "referrals",
        title: "Invitar amigos",

        icon: <img src={Users} height="30" alt="Bot Icon" />, // Aquí se utiliza el icono SVG importado como una imagen
        navLink: "/referrals",
    },

    {
        id: "genealogy",
        title: <IntlMessages id="mi-cuenta" />,
  
        icon: <img src={Account} height="30" alt="Account Icon" />, // Aquí se utiliza el icono SVG importado como una imagen
        navLink: "/pages/profile/personel-information",
    },
    {
      id: "transacctions",
      title: "Historial de transacciones",

      icon: <img src={Chart} height="30" alt="Bot Icon" />, // Aquí se utiliza el icono SVG importado como una imagen
      navLink: "/deposits",
    },
    {
      id: "packages",
      title: "Paquetes",

      icon: <img src={Chart} height="30" alt="Bot Icon" />, // Aquí se utiliza el icono SVG importado como una imagen
      navLink: "/buy-plans",
    },
    {
        id: "wallets",
        title: <IntlMessages id="wallets" />,
  
        icon: <img src={Wallet} height="30" alt="Account Icon" />, // Aquí se utiliza el icono SVG importado como una imagen
        navLink: "/wallets",
    },
    {
        id: "genealogy",
        title: "Soporte",

        icon: <img src={Support} height="30" alt="Account Icon" />, // Aquí se utiliza el icono SVG importado como una imagen
        navLink: "/pages/maintenance",
    },


];

export default apps