// axiosInstance.js
import axios from 'axios';

// Crea una instancia de Axios con la base URL deseada
const AxiosInstance = axios.create({
  //baseURL: 'https://billionsoffice.com:46137/', // Reemplaza con tu base URL
  //baseURL: 'http://104.238.136.136:3002/',
  baseURL: 'https://aulalavid-backend-production.up.railway.app/',
});

export default AxiosInstance;
